<template>
  <div>
    <div id="myMap"></div>
    <div v-if="showAll" class="text-area">
      <div class="search-content">
        <div v-for="(item,index) in searchList" :key="index"
             :class="{ current: tabCurrentIndex == index }" class="nav-item" @click="tabClick(index)">
          {{ item.name }}
        </div>
      </div>
      <div class="searchInput" @click="toSearch(searchList[tabCurrentIndex].type)">
        <img alt="" class="search-image" src="../../assets/home_nav_icon_search@2x.png"/>
        <input class="search-input" disabled="true" placeholder="请输入关键字"/>
      </div>
      <div class="toUp" @click="showAll = false">
        <img alt="" src="../../assets/icon_up@2x.png"/>
      </div>
    </div>
    <div v-else class="toUp toDown" @click="showAll = true">
      <img alt="" src="../../assets/icon_down@2x.png"/>
    </div>
    <div v-if="scale >= 10 && showTip" :class="{'tip2': !showAll}" class="tip">
      <span>点击企业标识，查看企业信息</span>
      <img alt="" src="../../assets/icon_close@2x.png" @click="closeTip(1)"/>
    </div>
    <div v-if="scale < 10 && showTip2" :class="{'tip2': !showAll}" class="tip">
      <span>放大地图或点击区域标识，查看更多</span>
      <img alt="" src="../../assets/icon_close@2x.png" @click="closeTip(0)"/>
    </div>
    <div v-show="!showDetail" class="box-content">
      <!--      <div class="box">-->
      <!--        <img class="shuoming" src="../../assets/home_nav_icon_search@2x.png" @click="showSearch" />-->
      <!--        <div>{{search ? '收起' : '搜索'}}</div>-->
      <!--      </div>-->
      <div class="box">
        <img alt="" class="shuoming" src="../../assets/icon_Instruction@2x.png" @click="showExplain"/>
        <div>说明</div>
      </div>
      <!--            <div class="box">-->
      <!--              <img class="list" src="../../assets/list@2x.png"-->
      <!--                   @click="toList(searchList[tabCurrentIndex].type)" />-->
      <!--              <div>列表</div>-->
      <!--            </div>-->
      <div class="box box3">
        <img alt="" class="location" src="../../assets/location@2x.png" @click="toLocation"/>
        <div>定位</div>
      </div>
      <!--      <div class="box box1 box2">-->
      <!--        <img class="add" src="../../assets/add@2x.png" @click="addScale"/>-->
      <!--      </div>-->
      <!--      <div class="box box1">-->
      <!--        <img class="reduce" src="../../assets/jian@2x.png" @click="reduceScale"/>-->
      <!--      </div>-->
    </div>
    <el-dialog :show-close="false" :visible.sync="showPopup" center title="说明">
      <div class="popup-view">
        <div class="popup-title">成品云工厂：提供石材生产与加工，将大板切割成为成品板的智能云工厂。</div>
        <div class="popup-title">大板云工厂：提供荒料生产与加工，将荒料切割为大板的智能云工厂</div>
        <div class="popup-title">材料商：销售石材的商家</div>
        <div class="uni-flex popup-buttons" @click="showExplain">
          <span class="uni-flex-item">知道了</span>
        </div>
      </div>
    </el-dialog>
    <!--    <el-carousel v-if="showDetail" :autoplay="false" :initial-index="initialIndex"-->
    <!--                 class="detailPosition" height="240px" indicator-position="none" @change="changeIndex">-->
    <!--      <el-carousel-item v-for="(item,index) in markers" :key="index" class="item">-->
    <!--        <marker-detail :detail="item" @close="close" @toDetail="toDetail" @toGo="toGo"-->
    <!--                       @toPhone="toPhone"/>-->
    <!--      </el-carousel-item>-->
    <!--    </el-carousel>-->
    <van-swipe v-if="showDetail" :initial-swipe="initialIndex" :show-indicators="false"
               :vertical="false" class="detailPosition" height="250" width="360" @change="changeIndex">
      <van-swipe-item v-for="(item, index) in markers" :key="index" class="item">
        <div class="itemContent">
          <marker-detail :detail="item" @close="close" @toDetail="toDetail" @toGo="toGo"
                         @toPhone="toPhone"/>
        </div>
      </van-swipe-item>
    </van-swipe>
    <el-dialog :show-close="false" :visible.sync="chooseMap" center title="使用地图打开">
      <open-app :keyword="detail.orgAddr" :latitude="detail.lat" :longitude="detail.lng"></open-app>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../../api'
import Utils from "../../utils";
import markerDetail from "../../components/marker-detail/marker-detail"
import openApp from "../../components/open-map-app/index.vue"

const utils = new Utils()

const api = new Api()
// 原始-单字母 选中-双字母 选过-三字母
const cssM = {
  borderWidth: "1px",
  borderColor: '#9931F1',
  // background: "#9931F1",
  // border: "none",
  borderRadius: "3px",
  width: '80px',
  maxWidth: '80px',
  whiteSpace: 'normal',
  background: '#ffffff'
};
const cssI = {
  borderWidth: "1px",
  borderColor: '#F15131',
  // background: "#F15131",
  // border: "none",
  borderRadius: "3px",
  width: '80px',
  maxWidth: '80px',
  whiteSpace: 'normal',
  background: '#ffffff'
};
const cssB = {
  borderWidth: "1px",
  borderColor: '#02AF66',
  // background: "#02AF66",
  // border: "none",
  borderRadius: "3px",
  width: '80px',
  maxWidth: '80px',
  whiteSpace: 'normal',
  background: '#ffffff'
};
const cssMM = {
  background: "#9931F1",
  border: "none",
  borderRadius: "3px",
  width: '80px',
  maxWidth: '80px',
  whiteSpace: 'normal'
};
const cssII = {
  background: "#F15131",
  border: "none",
  borderRadius: "3px",
  width: '80px',
  maxWidth: '80px',
  whiteSpace: 'normal'
};
const cssBB = {
  background: "#02AF66",
  border: "none",
  borderRadius: "3px",
  width: '80px',
  maxWidth: '80px',
  whiteSpace: 'normal'
};
const cssMMM = {
  borderWidth: "1px",
  borderColor: '#9931F1',
  borderRadius: "3px",
  width: '80px',
  maxWidth: '80px',
  whiteSpace: 'normal',
  background: '#EEDAFF'
};
const cssIII = {
  borderWidth: "1px",
  borderColor: '#F15131',
  borderRadius: "3px",
  width: '80px',
  maxWidth: '80px',
  whiteSpace: 'normal',
  background: '#FFF0ED'
};
const cssBBB = {
  borderWidth: "1px",
  borderColor: '#02AF66',
  borderRadius: "3px",
  width: '80px',
  maxWidth: '80px',
  whiteSpace: 'normal',
  background: '#E1FFF2'
};
export default {
  components: {
    markerDetail,
    openApp
  },
  data() {
    return {
      showAll: true,
      showTip: true,
      showTip2: true,
      initialIndex: 0,
      detail: {},
      chooseMap: false,
      showDetail: false,
      // 大板云工厂
      big_intelligent: require('../../assets/big_intelligent.png'),
      // 成品云工厂
      big_machining: require('../../assets/big_machining.png'),
      // 材料商
      big_buisness: require('../../assets/big_buisness.png'),
      // 大板云工厂
      intelligent: require('../../assets/icon_intelligent@2x.png'),
      // 成品云工厂
      machining: require('../../assets/icon_machining@2x.png'),
      // 材料商
      buisness: require('../../assets/icon_buisness@2x.png'),
      map: undefined,
      markersArray: [],
      overlays: [], // 存放所有覆盖物
      sourceData: [], // 原始数据
      listener: undefined, // 地图缩放或平移的事件监听器
      // 4 6 8 8以上
      scale: 4,
      showPopup: false,
      latitude: '37.477524',
      longitude: '111.788363',
      maxY: '48.18297834365542',
      minY: '24.98910283557587',
      maxX: '120.02810909375003',
      minX: '103.54861690625003',
      markers: [],
      tabCurrentIndex: 0,
      searchList: [
        {
          type: 0,
          name: '全部'
        },
        {
          type: 1,
          name: '大板云工厂'
        },
        {
          type: 2,
          name: '成品云工厂'
        },
        {
          type: 3,
          name: '材料商'
        }
      ],
      newScale: 4,
      oldScale: 4,
      newType: null,
      oldType: null
    }
  },
  mounted() {
    if (window.performance.navigation.type == 1) {
      // alert("页面被刷新")
      if (window.name != "index") {
        location.reload();
        window.name = "index";
      } else {
        window.name = "";
      }
    } else {
      const timer = new Date().getTime()
      if (localStorage.getItem('timer')) {
        // console.log(JSON.parse(localStorage.getItem('timer')))
        if (timer - JSON.parse(localStorage.getItem('timer')) > 1799430) {
          localStorage.removeItem('detail')
        }
      }
      localStorage.setItem('timer', JSON.stringify(timer))
      // alert("首次被加载")
    }
    if (localStorage.getItem('showTip')) {
      this.showTip = false
    }
    if (localStorage.getItem('showTip2')) {
      this.showTip2 = false
    }
    if (JSON.parse(localStorage.getItem('detail'))) {
      const detail = JSON.parse(localStorage.getItem('detail'))
      this.latitude = detail.lat
      this.longitude = detail.lng
      this.scale = 11
      this.maxY = detail.lat + 10
      this.minY = detail.lat - 10
      this.maxX = detail.lng + 10
      this.minX = detail.lng - 10
    }
    this.initMap()
    this.getMapProvince(this.maxY.toString(), this.minY.toString(), this.maxX.toString(), this.minX.toString())
  },
  watch: {
    sourceData: {
      handler(n, o) {
        this.drawOverlay()
      }
    },
    markers: {
      handler(n, o) {
        if (n != o) {
          this.drawMarker()
        }
      }
    },
    scale(n, o) {
      this.newScale = n
      this.oldScale = o
    },
    tabCurrentIndex(n, o) {
      this.newType = n
      this.oldType = o
    },
  },
  methods: {
    closeTip(num) {
      if (num) {
        this.showTip = false
        localStorage.setItem('showTip', 'notShowTip')
      } else {
        this.showTip2 = false
        localStorage.setItem('showTip2', 'notShowTip2')
      }
    },
    changeIndex(e) {
      this.markersArray[e].choosed = true
      this.detail = this.markers[e]
      this.showDetail = true
      this.markersArray.map(i => {
        i.label.setZIndex(10)
        if (i.choosed) {
          const style = i.data.orgType === 1 ? cssMMM : i.data.orgType === 2 ? cssIII : cssBBB
          i.label.setStyle(style)
          const content = i.data.orgType === 1 ? `<div class="marker"><div class="markers markers1">${i.data.orgName}</div><span class="span1 span11"></span></div>` : i.data.orgType === 2 ? `<div class="marker"><div class="markers markers2">${i.data.orgName}</div><span class="span2 span22"></span></div>` : `<div class="marker"><div class="markers markers3">${i.data.orgName}</div><span class="span3 span33"></span></div>`
          i.label.setContent(content)
        } else {
          const style = i.data.orgType === 1 ? cssM : i.data.orgType === 2 ? cssI : cssB
          i.label.setStyle(style)
          const content = i.data.orgType === 1 ? `<div class="marker"><div class="markers markers1">${i.data.orgName}</div><span class="span1 span"></span></div>` : i.data.orgType === 2 ? `<div class="marker"><div class="markers markers2">${i.data.orgName}</div><span class="span2 span"></span></div>` : `<div class="marker"><div class="markers markers3">${i.data.orgName}</div><span class="span3 span"></span></div>`
          i.label.setContent(content)
        }
      })
      const style = this.detail.orgType === 1 ? cssMM : this.detail.orgType === 2 ? cssII : cssBB
      this.markersArray[e].label.setStyle(style)
      const content = this.detail.orgType === 1 ? `<div class="marker"><div class="markers">${this.detail.orgName}</div><span class="span1"></span></div>` : this.detail.orgType === 2 ? `<div class="marker"><div class="markers">${this.detail.orgName}</div><span class="span2"></span></div>` : `<div class="marker"><div class="markers">${this.detail.orgName}</div><span class="span3"></span></div>`
      this.markersArray[e].label.setContent(content)
      this.markersArray[e].label.setZIndex(99)
    },
    close() {
      this.showDetail = false
    },
    // 导航
    toGo() {
      this.chooseMap = !this.chooseMap
    },
    // 打电话
    toPhone() {
      window.location.href = 'tel://' + this.detail.contactPhone
    },
    // 跳转详情
    toDetail(item) {
      localStorage.setItem('detail', JSON.stringify(this.detail))
      if (item.orgTypes.indexOf("3") != -1) {
        // 跳转到大板市场店铺页
        window.location.href = process.env.VUE_APP_SHOP_SERVER + '/Content/Vue/new-shop/my-shop.html?officeCode=' + item.orgId + '&isShare=2'
      } else {
        // 跳转到产业地图店铺页
        this.$router.push({
          path: "/detail",
          query: {
            orgId: item.orgId
          }
        });
      }
    },
    initMap() {
      const _this = this
      const center = new window.qq.maps.LatLng(Number(_this.latitude), Number(_this.longitude))
      _this.map = new window.qq.maps.Map(document.getElementById('myMap'), {
        center: center,
        zoom: Number(this.scale),
        minZoom: 4,
        maxZoom: 18,
        disableDefaultUI: true,
        scrollWheel: true,
        //启用缩放控件
        zoomControl: true,
        // 设置缩放控件的位置和样式
        zoomControlOptions: {
          //设置缩放控件的位置为相对左方中间位置对齐.
          position: qq.maps.ControlPosition.RIGHT_BOTTOM,
          //设置缩放控件样式为仅包含放大缩小两个按钮
          style: qq.maps.ZoomControlStyle.SMALL
        }
      })
      //当地图中心属性更改时触发事件
      window.qq.maps.event.addListener(_this.map, 'center_changed', function () {
        // _this.showDetail = false
        let bounds = _this.map.getBounds()
        _this.newScale = _this.oldScale
        _this.maxX = bounds.lng.maxX
        _this.maxY = bounds.lat.maxY
        _this.minY = bounds.lat.minY
        _this.minX = bounds.lng.minX
        _this.getMapProvince(bounds.lat.maxY.toString(), bounds.lat.minY.toString(), bounds.lng.maxX.toString(), bounds.lng.minX.toString())
      });
      window.qq.maps.event.addListener(_this.map, 'zoom_changed', function () {
        // _this.showDetail = false
        const oldScale = _this.scale
        _this.scale = _this.map.getZoom()
        let bounds = _this.map.getBounds()
        _this.maxX = bounds.lng.maxX
        _this.maxY = bounds.lat.maxY
        _this.minY = bounds.lat.minY
        _this.minX = bounds.lng.minX
        const scaleChange = Math.abs(_this.scale - oldScale)
        if (scaleChange >= 1) {
          _this.getMapProvince(bounds.lat.maxY.toString(), bounds.lat.minY.toString(), bounds.lng.maxX.toString(), bounds.lng.minX.toString())
        }
      });
      window.qq.maps.event.addListener(_this.map, 'click', function () {
        // _this.showDetail = false
        // _this.markersArray.map(i => {
        //   i.marker.setIcon(i.data.icon)
        // })
      });
    },
    clearMarker() {
      if (this.markersArray) {
        this.markersArray.map(item => {
          // item.marker.setMap(null);
          item.label.setMap(null);
        })
        this.markersArray = [];
      }
    },
    drawMarker() {
      const _this = this
      _this.clearOverlays()
      const changeScale = Math.abs(_this.newScale - _this.oldScale)
      const changeType = Math.abs(_this.newType - _this.oldType)
      if (changeScale > 0 || changeType > 0) {
        _this.clearMarker()
      }
      if (!_this.markers.length) {
        return
      }
      // const cssMM = {
      //   background: "#9931F1",
      //   border: "none",
      //   borderRadius: "3px",
      //   width: '80px',
      //   maxWidth: '80px',
      //   whiteSpace: 'normal'
      // };
      // const cssII = {
      //   background: "#F15131",
      //   border: "none",
      //   borderRadius: "3px",
      //   width: '80px',
      //   maxWidth: '80px',
      //   whiteSpace: 'normal'
      // };
      // const cssBB = {
      //   background: "#02AF66",
      //   border: "none",
      //   borderRadius: "3px",
      //   width: '80px',
      //   maxWidth: '80px',
      //   whiteSpace: 'normal'
      // };
      _this.markers.map(item => {
        if(this.tabCurrentIndex != 0) {
          item.orgType = this.tabCurrentIndex
        } else {
          item.orgTypes.indexOf('1') !== -1 ? item.orgType = 1 : item.orgTypes.indexOf('2') !== -1 ? item.orgType = 2 :  item.orgType = 3
        }
        //初始marker
        //初始化label
        let label = new window.qq.maps.Label({
          id: item.orgId,
          map: _this.map,
          position: new window.qq.maps.LatLng(item.lat, item.lng),
          zIndex: 10, // 信息窗的z-index值
          // offset: new qq.maps.Size(13, -38),
          offset: new qq.maps.Size(-42, -20),
          // offset: new qq.maps.Size(15, -10),
          style: item.orgType === 1 ? cssM : item.orgType === 2 ? cssI : cssB,
          enableCustom: true,
          content: item.orgType === 1 ? `<div class="marker"><div class="markers markers1">${item.orgName}</div><span class="span span1"></span></div>` : item.orgType === 2 ? `<div class="marker"><div class="markers markers2">${item.orgName}</div><span class="span span2"></span></div>` : `<div class="marker"><div class="markers markers3">${item.orgName}</div><span class="span span3"></span></div>`
        });
        _this.markersArray.push({label: label, choosed: false, data: item});
        window.qq.maps.event.addListener(label, 'click', function (e) {
          _this.detail = item
          _this.showDetail = true
          _this.markersArray.map((i, k) => {
            if (i.label.id == label.id) {
              i.choosed = true
              _this.initialIndex = k
              // i.label.setIcon(i.data.iconActive)
              i.label.setZIndex(99)
              const style = i.data.orgType === 1 ? cssMM : i.data.orgType === 2 ? cssII : cssBB
              i.label.setStyle(style)
              const content = i.data.orgType === 1 ? `<div class="marker"><div class="markers">${i.data.orgName}</div><span class="span1"></span></div>` : i.data.orgType === 2 ? `<div class="marker"><div class="markers">${i.data.orgName}</div><span class="span2"></span></div>` : `<div class="marker"><div class="markers">${i.data.orgName}</div><span class="span3"></span></div>`
              i.label.setContent(content)
            } else {
              if (i.choosed) {
                const style = i.data.orgType === 1 ? cssMMM : i.data.orgType === 2 ? cssIII : cssBBB
                i.label.setStyle(style)
                const content = i.data.orgType === 1 ? `<div class="marker"><div class="markers markers1">${i.data.orgName}</div><span class="span1 span11"></span></div>` : i.data.orgType === 2 ? `<div class="marker"><div class="markers markers2">${i.data.orgName}</div><span class="span2 span22"></span></div>` : `<div class="marker"><div class="markers markers3">${i.data.orgName}</div><span class="span3 span33"></span></div>`
                i.label.setContent(content)
              } else {
                const style = i.data.orgType === 1 ? cssM : i.data.orgType === 2 ? cssI : cssB
                i.label.setStyle(style)
                const content = i.data.orgType === 1 ? `<div class="marker"><div class="markers markers1">${i.data.orgName}</div><span class="span span1"></span></div>` : i.data.orgType === 2 ? `<div class="marker"><div class="markers markers2">${i.data.orgName}</div><span class="span span2"></span></div>` : `<div class="marker"><div class="markers markers3">${i.data.orgName}</div><span class="span span3"></span></div>`
                i.label.setContent(content)
              }
              // i.label.setIcon(i.data.icon)
              i.label.setZIndex(10)
            }
          })
        });
        // let marker = new window.qq.maps.Marker({
        //   id: item.orgId,
        //   //设置Marker的位置坐标
        //   position: new window.qq.maps.LatLng(item.lat, item.lng),
        //   //设置显示Marker的地图
        //   map: _this.map
        // });
        // //设置Marker的动画属性为从落下
        // marker.setAnimation(qq.maps.MarkerAnimation.DOWN);
        // //设置Marker是否可以被拖拽，为true时可拖拽，false时不可拖拽，默认属性为false
        // marker.setDraggable(false);
        // //设置Marker自定义图标的属性，size是图标尺寸，该尺寸为显示图标的实际尺寸，origin是切图坐标，该坐标是相对于图片左上角默认为（0,0）的相对像素坐标，anchor是锚点坐标，描述经纬度点对应图标中的位置
        // const size = new qq.maps.Size(40, 40),
        //     icon = new qq.maps.MarkerImage(
        //         Number(item.orgType) === 1 ? _this.machining : Number(item.orgType) === 2 ? _this.intelligent : _this.buisness,
        //         size
        //     );
        //
        // //标记Marker点击事件
        // const sizeActive = new qq.maps.Size(57, 66),
        //     iconActive = new qq.maps.MarkerImage(
        //         Number(item.orgType) === 1 ? _this.big_machining : Number(item.orgType) === 2 ? _this.big_intelligent : _this.big_buisness,
        //         sizeActive
        //     );
        // marker.setZIndex(2)
        // marker.setIcon(icon);
        // item.icon = icon;
        // item.iconActive = iconActive
        // _this.markersArray.push({marker: marker, label: label, data: item});
        // window.qq.maps.event.addListener(marker, 'click', function (e) {
        //   _this.detail = item
        //   _this.showDetail = true
        //   _this.markersArray.map(i => {
        //     if (i.marker.id == marker.id) {
        //       i.marker.setIcon(i.data.iconActive)
        //       i.marker.setZIndex(99)
        //     } else {
        //       i.marker.setIcon(i.data.icon)
        //       i.marker.setZIndex(2)
        //     }
        //   })
        // });
      })
    },
    // 清除自定义覆盖物
    clearOverlays() {
      let overlay
      while (overlay = this.overlays.pop()) {
        overlay.onclick = null // 移除点击事件
        overlay.parentNode.removeChild(overlay) // 移除 dom 元素
      }
    },
    drawOverlay() {
      const _this = this
      _this.clearMarker()
      const changeScale = Math.abs(_this.newScale - _this.oldScale)
      const changeType = Math.abs(_this.newType - _this.oldType)
      if (changeScale > 0 || changeType > 0) {
        _this.clearOverlays()
      }
      if (!_this.sourceData.length) {
        return
      }
      // 自定义覆盖物
      if (window.CustomOverlay === undefined) {
        window.CustomOverlay = function (lat, lng, name, count) {
          // 调用地图 api 计算出覆盖物的位置
          this.position = new window.qq.maps.LatLng(lat, lng)
          this.lat = lat
          this.lng = lng
          this.name = name // 区域名
          this.count = count // 数量
        }
        // 继承 Overlay
        window.CustomOverlay.prototype = new window.qq.maps.Overlay()
        // 自定义覆盖物构造函数，定义覆盖为的 DOM 结构，DOM 结构，样式大家可以根据需求自己绘制
        window.CustomOverlay.prototype.construct = function () {
          let div = this.div = document.createElement('div')
          div.className = 'my-overlay' // 覆盖物类名
          // 覆盖物 html 结构
          this.div.innerHTML = `<p class="count">厂家：${this.count}</p><p class="name">${this.name}</p>`
          //将dom添加到覆盖物层，overlayMouseTarget的顺序容器 5，此容器包含透明的鼠标相应元素，用于接收Marker的鼠标事件
          this.getPanes().overlayMouseTarget.appendChild(div)
          // 将 div 添加到 overlays,可以用以后续处理
          _this.overlays.push(div)
          // 定义覆盖物的点击事件
          let center = this.position
          this.div.onclick = function () {
            // 点击之后对地图进行缩放以及平移
            if (_this.scale < 6) {
              _this.scale = 6
              _this.map.setCenter(center)
              _this.map.setZoom(6)
            } else if (_this.scale >= 6 && _this.scale < 8) {
              _this.scale = 8
              _this.map.setCenter(center)
              _this.map.setZoom(8)
            } else if (_this.scale >= 8 && _this.scale < 10) {
              _this.scale = 10
              _this.map.setCenter(center)
              _this.map.setZoom(10)
            } else {
              // 覆盖物消失-显示marker
              _this.map.setZoom(10)
            }
          }
        }

        // 实现 draw 接口来绘制 DOM 元素
        window.CustomOverlay.prototype.draw = function () {
          let overlayProjection = this.getProjection()
          // 获取覆盖物容器的相对像素坐标
          let pixel = overlayProjection.fromLatLngToDivPixel(this.position)
          let divStyle = this.div.style
          // 根据 DOM 元素调整定位的位置
          divStyle.top = pixel.y - 53 + 'px'
          divStyle.left = pixel.x - 30 + 'px'
        }
      }

      // 根据接口数据绘制覆盖物
      if (_this.sourceData.length > 0) {
        _this.sourceData.map(item => {
          let customOverlay = new window.CustomOverlay(item.lat, item.lng, item.name, item.count)
          customOverlay.setMap(_this.map)
        })
      }
    },
    getMapProvince(maxY, minY, maxX, minX) {
      const param = {
        orgType: this.tabCurrentIndex.toString(),
        scale: this.scale.toString(),
        maxLat: maxY,
        minLat: minY,
        maxLng: maxX,
        minLng: minX,
      }
      api.getMapProvince(param).then(res => {
        this.sourceData = res.data
        if (this.scale >= 10) {
          this.sourceData = []
          res.data.map(item => {
            const list = item.orgTypeList.join(' ')
            list.indexOf('1') !== -1 ? item.orgType = 1 : list.indexOf('2') !== -1 ? item.orgType = 2 : item.orgType = 3
          })
          this.markers = res.data
        }
      })
    },
    // getMapCity(code) {
    //   const param = {
    //     orgType: this.tabCurrentIndex,
    //     areaCode: code
    //   }
    //   api.getMapCity(param).then(res => {
    //     res.data.map(item => {
    //       if(item.areaCode === '') {
    //         this.sourceData = []
    //         this.markers = res.data
    //       } else {
    //         this.markers = []
    //         this.sourceData = res.data
    //       }
    //     })
    //     if(this.scale >= 10) {
    //       localStorage.setItem('areaCode', code)
    //       this.sourceData = []
    //       this.markers = res.data
    //       console.log(this.markers)
    //     }
    //   })
    // },
    showExplain() {
      this.showPopup = !this.showPopup
    },
    // toList(type) {
    //   this.getLocation()
    //   this.$router.push({
    //     path: "/list",
    //     query: {
    //       lng: this.longitude,
    //       lat: this.latitude,
    //       orgType: type
    //     }
    //   });
    // },
    getLocation() {
      // 获取当前位置
      const geolocation = new window.qq.maps.Geolocation("SNKBZ-KTS3S-U6HOK-6SDPM-S3PQE-J2B24", "lsboot-imap-h5")
      geolocation.getLocation(this.showLocation, this.showError)
    },
    showLocation(position) {
      let center = new window.qq.maps.LatLng(position.lat, position.lng)
      this.map.setCenter(center)
      this.map.setZoom(15)
      let marker = new window.qq.maps.Marker({
        //设置Marker的位置坐标
        position: new window.qq.maps.LatLng(position.lat, position.lng),
        //设置显示Marker的地图
        map: this.map
      });
      marker.setAnimation(qq.maps.MarkerAnimation.DOWN);
    },
    showError() {
      utils.message("定位失败");
      // this.getMyLocation();
    },
    toLocation() {
      this.getLocation()
    },
    //顶部tab点击
    tabClick(index) {
      this.tabCurrentIndex = index;
      this.getMapProvince(this.maxY.toString(), this.minY.toString(), this.maxX.toString(), this.minX.toString())
    },
    // 查看地图详情
    toMap(item) {
      this.$router.push({
        path: "/map",
        query: {
          orgId: item.orgId,
          orgType: item.orgType,
          latitude: item.lat,
          longitude: item.lng
        }
      });
    },
    toSearch(type) {
      this.$router.push({
        path: "/search",
        query: {
          type: type
        }
      });
      localStorage.removeItem('detail')
    }
  }
}
</script>
<style>
.my-overlay {
  position: absolute;
  width: 75px;
  height: 75px;
  background: #01AC64;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 14px;
  font-family: PingFangSC-, PingFang SC;
  font-weight: normal;
  line-height: 20px;
}

.marker {
  position: relative;
  /*color: #ffffff;*/
  text-align: center;
  padding: 1px 2px;
  font-weight: normal;
}

.markers {
  color: #ffffff;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.markers1 {
  color: #9931F1;
}

.markers2 {
  color: #F15131;
}

.markers3 {
  color: #02AF66;
}

.marker span {
  position: absolute;
  border-left: solid 7px transparent;
  border-right: solid 7px transparent;
  top: 106%;
  left: 50%;
  margin-left: -7px;
  width: 0;
  height: 0;
}

.marker .span:after {
  content: '';
  top: 106%;
  left: 50%;
  margin-left: -6px;
  border-top: 8px solid #FFFFFF;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.marker .span11:after {
  content: '';
  top: 106%;
  left: 50%;
  margin-left: -6px;
  border-top: 8px solid #EEDAFF;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.marker .span22:after {
  content: '';
  top: 106%;
  left: 50%;
  margin-left: -6px;
  border-top: 8px solid #FFF0ED;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.marker .span33:after {
  content: '';
  top: 106%;
  left: 50%;
  margin-left: -6px;
  border-top: 8px solid #E1FFF2;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.span1 {
  border-top: solid 8px #9931F1;
}

.span2 {
  border-top: solid 8px #F15131;
}

.span3 {
  border-top: solid 8px #02AF66;
}

.name {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.count {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}

.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-dialog {
  margin-top: 0 !important;
  border-radius: 10px !important;
  width: 80% !important;
}

.el-dialog__title {
  font-size: 18px;
  line-height: 18px !important;
  color: #333 !important;
  margin-bottom: 10px;
  font-weight: 600;
}

.el-dialog__body {
  padding-top: 0 !important;
}

.el-message-box {
  width: 300px !important;
}

#app a div:first-child {
  width: 80px !important;
  height: 20px !important;
}

#app a div:first-child img {
  width: 80px !important;
  min-width: 80px !important;
  height: 20px !important;
  min-height: 20px !important;
}

.smnoprint div {
  width: 40px !important;
}
</style>
<style lang="less" scoped>
.detailPosition {
  position: absolute;
  bottom: 10px;
  background: none;
  margin-left: 10px;

  .item {
    width: 80%;

    .itemContent {
      border-radius: 12px 12px 18px 18px;
      background: #FFFFFF;
      margin-right: 10px;
    }
  }
}

//.detailPosition {
//  position: absolute;
//  bottom: 10px;
//  left: 2%;
//  right: 2%;
//  background: none;
//
//  .item {
//    //width: 80%;
//    border-radius: 12px;
//    //margin-left: 10px;
//    background: #FFFFFF;
//  }
//}
.toUp {
  display: flex;
  justify-content: center;
  padding-bottom: 12px;

  img {
    width: 25px;
    height: 10px;
  }
}

.toDown {
  position: absolute;
  top: 20px;
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.tip {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 165px;
  width: 58%;
  padding: 0 10px;
  height: 22px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 28px;
  font-size: 10px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 7px;
    width: 14px;
    height: 14px;
  }
}

.tip2 {
  top: 40px;
}

#myMap {
  /*地图(容器)显示大小*/ /*设置为全屏，位于最下层*/
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
}

.popup-view {
  background: #FFFFFF;
  border-radius: 6px;

  .popup-title {
    text-align: left;
    font-size: 14px;

    font-weight: 400;
    color: #323233;
    line-height: 20px;
    margin-bottom: 15px;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-line;
  }

  .title1 {
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500 !important;
    line-height: 22px;
    color: #333;
    margin-bottom: 8px;
  }

  .popup-buttons {
    margin-top: 33px;
    height: 36px;

    .uni-flex-item {
      font-size: 14px;

      font-weight: 400;
      color: #FFFFFF;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 14px;
      background: #01AC64;
      border-radius: 18px;
    }
  }
}

.text-area {
  position: absolute;
  top: calc(20px + 44px);
  width: 90%;
  //width: 345px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;

  .search-content {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;

    .nav-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 14px;
      color: #666;
      position: relative;
      //margin-left: 26px;

      &:first-child {
        //margin-left: 29px;
      }

      &.current {
        color: #02AF66;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          width: 30px;
          height: 0;
          border-bottom: 2px solid #02AF66;
        }
      }
    }
  }

  .searchInput {
    padding: 0 15px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .search-input {
      text-align: left;
      padding: 0 0 0 35px;
      font-size: 13px;
      width: 100%;
      height: 34px;
      color: #666;
      border: none;
      font-weight: 400;
      background: #F5F6F7;
      border-radius: 17px;
    }

    .search-image {
      z-index: 2;
      width: 14px;
      height: 14px;
      position: absolute;
      left: 28px;
      top: 50%;
      margin-top: -7px;
    }
  }
}

.box-content {
  margin-top: 190px;
  //margin-right: 15px;
  float: right;
}

.box {
  min-width: 20px;
  padding: 9px 10px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  font-size: 10px;
  line-height: 14px;
  color: #333;
  margin-bottom: 10px;
  border-radius: 2px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);

  .shuoming {
    width: 15px;
    height: 15px;
  }

  .list {
    width: 13px;
    height: 12px;
  }

  .location {
    width: 20px;
    height: 20px;
  }

  .add {
    width: 13px;
    height: 14px;
  }

  .reduce {
    width: 13px;
    height: 2px;
  }
}

.box3 {
  height: 36px;
}

.box1 {
  height: 22px;
}

.box2 {
  margin-top: 120px;
}
</style>
